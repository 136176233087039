import service from "@/utils/request.js"


export const getContent = () => {
 return service.get("/api/ay/content-sort")
}

export const getContentDetail = (id) => {
 return service.get(`/api/ay-content-sort/${id}`)
}

export function getMetaverse(params) {
 return service.get("/api/ay-vr", { params });
}