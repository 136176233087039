import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: (state) => ({
    classify: state.classify,
  }),
});

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    curRoute: "", // 当前路由
    paramsId: "", // 当前路由params传参id
  },
  actions: {},
  mutations: {
    updateCurRoute(state, curRoute) {
      state.curRoute = curRoute;
    },
    updateParamsId(state, paramsId) {
      state.paramsId = paramsId;
    },
  },
  getters: {},
  plugins: [vuexLocal.plugin],
});

export default store;
