<template>
  <div class="sideList">
    <a
      class="sideList__item sideList__qq"
      href="http://wpa.qq.com/msgrd?v=1&amp;uin=506425701&amp;site=qq&amp;menu=yes"
      rel="nofollow"
      target="_blank"
      @mouseenter="MouseFun('show1')"
      @mouseleave="mouseleave('show1')"
    >
      <div class="sideList__topOutside">
        <transition name="fade">
          <div class="sideList__topShow sideList__show" v-if="show1">
            在线咨询
            <div class="sideList__triangle"></div>
          </div>
        </transition>
      </div>
    </a>
    <a
      class="sideList__item sideList__tel"
      @mouseenter="MouseFun('show2')"
      @mouseleave="mouseleave('show2')"
    >
      <div class="sideList__topOutside">
        <transition name="fade">
          <div class="sideList__topShow sideList__show" v-if="show2">
            400-0960-592
            <div class="sideList__triangle"></div>
          </div>
        </transition>
      </div>
    </a>
    <!-- <img src="./../static/" alt=""> -->
    <div class="sideList__item sideList__top" @click="toTop"></div>
  </div>
</template>

<script>
export default {
  name: "sideList",
  data() {
    return {
      router: true,
      defaultActive: "/",
      isShow: false,
      show1: false,
      show2: false,
    };
  },
  mounted() {},
  methods: {
    toTop() {
      let scrollTop = document.documentElement.scrollTop - 0;
      let t = setInterval(function () {
        // 匀速效果在滚动条的滚动效果上交互不好看，我们可以使用缓动动画

        // 缓动动画，每次走剩余距离的一半
        scrollTop = scrollTop / 2;
        // 自己定义临界值
        if (scrollTop <= 5) {
          clearInterval(t);
          scrollTop = 0;
        }
        document.documentElement.scrollTop = scrollTop + 0;
      }, 16.666);
    
    },
    handleSelect() {
      this.isShow = true;
    },
    mouseleave(type) {
      this[type] = false;
    },
    MouseFun(type) {
      // 停止定时器            // 重新执行定时器
      this[type] = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.sideList {
  position: fixed;
  width: 48px;
  z-index: 50;
  top: 80vh;
  margin: -100px 0 0 0;
  right: 10px;
  background-color: #111111;
  border-radius: 20px;
}

.sideList__item {
  height: 48px;
  background-position: center;
  display: block;
  cursor: pointer;
}

.sideList__tel {
  background-image: url(../assets/icon/tel-side.png);
}

.sideList__top {
  background-image: url(../assets/icon/top-side.png);
}

.sideList__qq {
  background-image: url(../assets/icon/qq-side.png);
}

.sideList__topOutside {
  margin-right: 50px;
  width: 150px;
  height: 48px;
  float: right;
  background-color: transparent;
}

.sideList__topShow {
  color: white;
  float: right;
  width: 120px;
  height: 48px;
  margin-right: 4px;
  border-radius: 8px;
  text-align: center;
  line-height: 48px;
  font-size: 15px;
  background-color: #111111;
}

.sideList__tel .sideList__topShow {
  font-size: 13px;
}

.sideList__triangle {
  width: 0;
  height: 0;
  border-width: 10px 10px 10px 0px;
  border-style: solid;
  border-color: transparent #d7d7d7;
  float: right;
  margin-top: 14px;
}
</style>
